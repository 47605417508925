import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import { updateLocation, signupLoading, updatePageHash } from "./redux/App/actions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import logo from './logo.svg';
import './App.css';
import {PolkadotGo} from "./components/pages";
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers/react';

// const projectId = '95ff87bbe3214c976bf1698c3f6abe6a'

// const wcChains = [{
//     chainId: 1,
//     name: 'Ethereum',
//     currency: 'ETH',
//     explorerUrl: 'https://etherscan.io',
//     rpcUrl: 'https://cloudflare-eth.com'
// },{
//   chainId: 1284,
//   name: 'Moonbeam',
//   currency: 'GLMR',
//   explorerUrl: 'https://moonscan.io/',
//   rpcUrl: 'https://rpc.api.moonbeam.network'
// }];

// const wcMetadata = {
//     name: 'Polkadot World',
//     description: 'Polkadot Event Activations & Engagement',
//     //url: 'https://www.polkadot.world',
//     url: 'http://localhost:3001',
//     icons: ['https://www.exiledracers.com/favicon-96x96.png']
// }

// const ethersConfig = defaultConfig({
//     metadata: wcMetadata,
//     /*Optional*/
//     enableEIP6963: true, // true by default
//     enableInjected: true, // true by default
//     enableCoinbase: true, // true by default
//     rpcUrl: '...', // used for the Coinbase SDK
//     defaultChainId: 1, // used for the Coinbase SDK
// });

// const wcModal = createWeb3Modal({
//     ethersConfig,
//     chains: wcChains,
//     projectId,
//     enableAnalytics: true // Optional - defaults to your Cloud configuration
// });

function App() {
  const [appState, setAppState] = useState(false);

  if(appState == false) {
      setAppState(true);
  }
  
  return (
    <div>
      <HashRouter basename="/">
        <Routes>
          <Route path="dotgo" element={<PolkadotGo />} />
          <Route path="/" element={<PolkadotGo  />} />
          <Route path="*" element={<PolkadotGo />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
