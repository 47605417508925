 const initialState = {
  racerContract: null,
  mintPackContract: null,
  pilotContract: null,
  boosterContract: null,
  racerMintPrice: 0,
  txHash: null,
  hasTxError: false,
  txErrorMsg: null,
  txStatus: null,
  txReceipt: null,
  mintIds: [],
};

const Web3Reducer = (state = initialState, action) => {
  //console.log({ action });
  switch (action.type) {
    case "SET_RACER_CONTRACT":
      return {
        ...state,
        racerContract: action.payload,
      };

    case "SET_PILOT_CONTRACT":
      return {
        ...state,
        pilotContract: action.payload,
      };

    case "SET_BOOSTER_CONTRACT":
      return {
        ...state,
        boosterContract: action.payload,
      };

    case "SET_MINT_PACK_CONTRACT":
      return {
        ...state,
        mintPackContract: action.payload,
      };

    case "SET_RACER_MINT_PRICE":
      return {
        ...state,
        racerMintPrice: action.payload,
      };

    case "SET_TX_HASH":
      return {
        ...state,
        txHash: action.payload,
      };

    case "SET_TX_STATUS":
      return {
        ...state,
        txStatus: action.payload,
      };

    case "SET_TX_ERROR":
      return {
        ...state,
        hasTxError: true,
        txErrorMsg: action.payload,
      };

    case "SET_TX_RECEIPT":
      return {
        ...state,
        txReceipt: action.payload,
      };

    case "ADD_MINT_ID":
      let existingIds = state.mintIds;
      return {
        ...state,
        mintIds: [...existingIds, action.payload],
      };

    default:
      return state;
  }
};

export default Web3Reducer;
