import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, ethers } from 'ethers';
import UniversalProvider from '@walletconnect/universal-provider'
import { WalletConnectModal } from '@walletconnect/modal';

import { setUserSignOn, setLoginWallet } from "../../../../../redux/Account/actions";
import {validateEmailText, sendEventEmailCode, checkEventEmailCode, addEventSignupWallet} from "../../../../../utils/signon";
import {getEventUserInfo, addEventUserAction, WalletConnectModalSetup, useWalletStatus} from "../../../../../utils/signon";
import {axiosInstance, startPageModals, openPageModal } from "../../../../../utils";
import {HeaderMintItem, DownloadNovaModal} from "../../../../organisms";

import $ from "jquery";

let current_event_user_id = 0; 
let current_wallet_address = '';

export const SplashMintPage = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { userID, eventUserID, walletID } = useSelector((state:any) => state.account);
    const { address, chainId, isConnected } = useWalletStatus();
    const { walletProvider } = useWeb3ModalProvider();

    const [pageSet, setPage] = useState(false);
    const [provider, setProvider] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("");
    const [current_wallet, setCurrentWallet] = useState("");
    const [walletNetwork, setWalletNetwork] = useState('CONNECT');
    const [walletNetworkAddress, setWalletNetworkAddress] = useState('Not Connected');
    const [registration_level, setRegistrationlevel] = useState('REGISTRATION COMPLETE');
    const [itemLogo,setItemLogo] = useState('');
    const [itemBackdrop,setItemBackdrop] = useState('');
    const [itemImage,setItemImage] = useState('https://media.exiledracers.com/dot/polkadot_safe_purple.png');
    const [collectItemButton,setCollectItemButton] = useState(<></>);
    const [nft_name,setNFTName] = useState('');
    const [koda_txn_link,setKodaTxnLink] = useState('https://kodadot.xyz/ahp/u/'+walletID+'?tab=owned&buy=true&sale=true&list=false');

    /* Event ID */
    var partnereventid = 1;
    var partnershortcode = 'dot';
    var apikey = 'h2zcswrivdly7d6v9uo6';
    let signup_user_id = 0;
    let item_collected_flag = 0;
    let logo_image = 'css/images/polkadotgo_logo_tmp.png'
    logo_image = 'css/images/play_with_polkadot_tmp_logo.png'

    let bg_primary_splash = 'css/images/polkadot_booth_design_play.png';

    let current_wallet_photo = "css/images/nova_icon_color.png";


    var event_item_id = 0;
    var item_permalink = '';
    var itemhash = window.location.href.split("#/");
    if(itemhash.length > 1) {
        var itemhash2 = itemhash[1].replace("#/",'');
        var itemhash3 = itemhash2.split("/");
        if(itemhash3.length > 1) {
            item_permalink = itemhash3[1].replace("/",'');
        }
    }


    useEffect(() => {
        current_event_user_id = eventUserID;
    }, []);

    function grabEventItem() {

        var addData:any = new Object();
        addData.apikey = encodeURIComponent(apikey);
        addData.event_id = encodeURIComponent(partnereventid);
        addData.event_user = encodeURIComponent(eventUserID);

        axiosInstance.post('event/event_items/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_item_list = result.data;   
                loadEventItem(new_item_list)
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function loadEventItem(item_list:any) {
        var itemlist:any = [];
        for(var i = 0; i < item_list.length;i++) {
            var item_id = item_list[i]['event_item_id'];
            var actual_item_permalink = item_list[i]['item_permalink'];
            var item_name_split = item_list[i]['item_name'].split(" POAP");
            var item_name = item_name_split[0];
            var item_logo = 'https://media.polkadot.world/global/polkadot_'+item_name.toLowerCase()+'_logo.png';
            var item_backdrop = 'https://media.polkadot.world/global/polkadot_'+item_name.toLowerCase()+'_backdrop.png';
            var items_collected = item_list[i]['items_collected'];
            var item_image = item_list[i]['item_image_url'];

            if((item_permalink ==  actual_item_permalink)&&(actual_item_permalink.length > 0)) {
                event_item_id = item_id;
                var item_collected = 'COLLECT NOW';
                var collectedclass = '';
                item_icon = <></>;
                if ((items_collected >= 1)) {
                    collectedclass = ' pink-green-button-active';
                    item_collected = 'COLLECTED';
                    var item_icon = <span className="material-symbols-outlined polkadot-purple-text" style={{fontSize:'30px',verticalAlign:'text-bottom'}}>editor_choice</span>;
                    item_collected_flag = 1;
                    $("#nft-mint-view").show();
                    $("#main-mint-locked").hide();
                    var mint_image = document.getElementById("main-mint-image");
                    if(mint_image) {
                        mint_image.style.filter = '';
                    }
                }

                if(current_event_user_id == 0) {
                    item_collected = 'REGISTER TO MINT';
                }
                
                setItemLogo(item_logo);
                setItemBackdrop(item_backdrop);
                setItemImage(item_image);

                var tmp_button = <span className={"center-align pink-green-button"+collectedclass} 
                                    style={{fontSize:'22px',padding:'15px 40px 15px 30px'}} 
                                    title="Collect NFT" onClick={()=>checkNFTAirdrop()}>
                                    {item_icon}<span style={{verticalAlign:'text-bottom',marginLeft:'10px'}} >{item_collected}</span>
                                </span>;

                setCollectItemButton(tmp_button);
                setNFTName(item_name);
            }
        }
    }

    function checkNFTAirdrop() {
        if(current_event_user_id == 0) {
            $("#nft-mint-button").hide();
            showRegistrationForm();
        } else {
            if((item_collected_flag == 0)&&(event_item_id > 0)) {
                $("#nft-mint-button").hide();
                $("#nft-mint-loading").fadeIn(1000);

                var addData:any = new Object();
                    addData.apikey = encodeURIComponent(apikey);
                    addData.event_id = encodeURIComponent(partnereventid);
                    addData.event_user = encodeURIComponent(eventUserID);
                    addData.event_item = encodeURIComponent(event_item_id);

                    // setTimeout(function() {
                        
                    //     var mint_image = document.getElementById("main-mint-image");
                    //     if(mint_image) {
                    //         $("#main-mint-image").fadeOut(2000);
                    //         $("#main-mint-locked").fadeOut(1000);
                    //         setTimeout(function() {
                    //             if(mint_image) {
                    //                 mint_image.style.filter = '';
                    //             }
                    //         },2000);
                    //         setTimeout(function() {
                    //             $("#main-mint-image").fadeIn();
                    //         },500);
                    //     }
                    //     showItemCollected();
                    // },5000);

                axiosInstance.post('event/user_collect_item/',addData).then(result => {
                    if(Object.keys(result.data).length > 0) {
                        var mint_result = result.data;
                        if(mint_result.length > 0) {
                            if(mint_result[0]['status'] == 'success') {
                                setTimeout(function() {
                                    var mint_image = document.getElementById("main-mint-image");
                                    if(mint_image) {
                                        $("#main-mint-image").fadeOut(2000);
                                        $("#main-mint-locked").fadeOut(1000);
                                        setTimeout(function() {
                                            if(mint_image) {
                                                mint_image.style.filter = '';
                                            }
                                        },2000);
                                        setTimeout(function() {
                                            $("#main-mint-image").fadeIn();
                                        },500);
                                    }
                                    showItemCollected();
                                },5000);
                            } else {
                                $("#nft-mint-button").fadeIn();
                                $("#nft-mint-loading").hide();
                                alert('Something Went Wrong. Try Again.');
                            }
                        }
                        
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    function showItemCollected() {
        // COLLECT NFT SCRIPT
        var collectedclass = ' pink-green-button-active';
        var item_collected = 'COLLECTED';
        var item_icon = <span className="material-symbols-outlined polkadot-purple-text" style={{fontSize:'30px',verticalAlign:'text-bottom'}}>editor_choice</span>;
        item_collected_flag = 1;

        var tmp_button = <span className={"center-align pink-green-button"+collectedclass} 
                                style={{fontSize:'22px',padding:'15px 40px 15px 30px'}} 
                                title="Collect NFT" onClick={()=>checkNFTAirdrop()}>
                                {item_icon}<span style={{verticalAlign:'text-bottom',marginLeft:'10px'}} >{item_collected}</span>
                        </span>;

        setCollectItemButton(tmp_button);

        $("#nft-mint-loading").hide();
        $("#nft-mint-button").fadeIn(2000);
        $("#nft-mint-view").fadeIn(2000);
    }


    function checkForWalletAddress() {
        let newaddress = props.wcModal.getAddress();
        let walletconnected = props.wcModal.getIsConnected();
        if((newaddress !== undefined)&&(walletconnected)&&((newaddress != current_wallet_address)||(walletNetwork == 'CONNECT'))) {
            current_wallet_address = newaddress;
            var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-10);
            
            dispatch(setLoginWallet(current_wallet_address));
            let newnetwork = props.wcModal.getWalletProvider();
            let newchain = props.wcModal.getChainId();
            setWalletNetwork(obf_wallet);
            setWalletNetworkAddress('Wallet Connected');
            $("#racerWalletConnected").val(obf_wallet);
            $("#connected-wallet").fadeIn();

            setTimeout(function(){
                checkForWalletAddress();
            },2000);
        } else {
            if((!walletconnected)&&(walletNetwork != 'CONNECT')) {
                setWalletNetwork('CONNECT');
                setWalletNetworkAddress('Not Connected');
                $("#racerWalletConnected").val('');
                $("#connected-wallet").fadeOut();
            }
            setTimeout(function(){
                checkForWalletAddress();
            },2000);
        }
    }

    async function connectNovaWallet() {
        
        const projectId = '95ff87bbe3214c976bf1698c3f6abe6a';
        const provider = await UniversalProvider.init({
          projectId: projectId,
          relayUrl: 'wss://relay.walletconnect.com'
        }); 
        const params = {
          requiredNamespaces: {
            polkadot: {
              methods: ['polkadot_signTransaction', 'polkadot_signMessage'],
              chains: [
                'polkadot:91b171bb158e2d3848fa23a9f1c25182', // polkadot
              ],
              events: ["chainChanged", "accountsChanged"]
            }
          }
          
        }

        const { uri, approval } = await provider.client.connect(params);
        const walletConnectModal = new WalletConnectModal({
          projectId: projectId,
          themeMode: 'dark',
          explorerRecommendedWalletIds: [
            '43fd1a0aeb90df53ade012cca36692a46d265f0b99b7561e645af42d752edb92',
            '9ce87712b99b3eb57396cc8621db8900ac983c712236f48fb70ad28760be3f6a',
          ],
          explorerExcludedWalletIds: 'ALL',
        });

        
    
        if (uri) {
          walletConnectModal.openModal({ uri })
        }
        // await session approval from the wallet app
        const walletConnectSession = await approval();
      
        const walletConnectAccount = Object.values(walletConnectSession.namespaces)
          .map(namespace => namespace.accounts)
          .flat();


        provider.on("proposal_expire", (event:any) => {
            // proposal expired and any modal displaying it should be removed
            event.preventDefault();
            const { id } = event;
            if(walletConnectModal) {
                walletConnectModal.closeModal();
            }
        });

        // grab account addresses from CAIP account formatted accounts
        const accounts = walletConnectAccount.map(wcAccount => {
          const address = wcAccount.split(':')[2]
          let newaddress = address;
          if((newaddress !== undefined)&&((newaddress != current_wallet_address)||(walletNetwork == 'CONNECT'))) {
              current_wallet_address = newaddress;
              var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-10);
              
              dispatch(setLoginWallet(current_wallet_address));
              setWalletNetwork(obf_wallet);
              setWalletNetworkAddress('Wallet Connected');
              $("#racerWalletConnected").val(obf_wallet);
              $("#connected-wallet").fadeIn();
  
              return address;
          } 
        });
        walletConnectModal.closeModal();
    }

    async function submitWalletSaved() {
        if(current_wallet_address.length < 5) {
            alert('Connect A Wallet');
        } else {
            $("#wallet-save-button").hide();
            $("#wallet-save-loading").show();
            let wallet_info:any = await addEventSignupWallet(apikey, partnereventid, current_wallet_address);

            if(wallet_info) {
                if(wallet_info['status'] == 'duplicate_wallet') {
                    // $("#wallet-save-loading").hide();
                    // $("#wallet-save-button").show();
                    // alert('This Wallet Is Already Registered. Connect a Different Wallet Later or Login to your Account.');
                    showWalletSaved();
                } else if(wallet_info['status'] == 'no_account') {
                    $("#wallet-save-loading").hide();
                    $("#wallet-save-button").show();
                    alert('This Email Account is not Registered. Go Back and Complete the Signup Process.');
                } else if(wallet_info['status'] == 'confirmed') {
                    let userid = wallet_info['login_info']['user'];
                    let event_user = wallet_info['login_info']['event_user'];
                    let wallet = wallet_info['login_info']['wallet'];
                    let logintime = wallet_info['login_info']['logintime'];
                    let logintoken = wallet_info['login_info']['logintoken'];
                    current_event_user_id = event_user;
                    dispatch(setUserSignOn(userid,event_user,logintoken,logintime));
                    dispatch(setLoginWallet(current_wallet_address));
                    
                    setKodaTxnLink('https://kodadot.xyz/ahp/u/'+current_wallet_address+'?tab=owned&buy=true&sale=true&list=false');

                    showWalletSaved();
                } else {
                    $("#wallet-save-loading").hide();
                    $("#wallet-save-button").show();
                    alert('Whoops! Something Went Wrong. Update and Try Again');
                }
            }
            
        }
    }


    function showWalletSaved() {

        setTimeout(function(){
            $("#wallet-save-loading").hide();
            $("#register-email-code").hide();
            $("#email-sent-text").hide();
            $("#register-wallet").hide();
            $("#connected-wallet").hide();
            $("#home-value-props2").hide();

            checkNFTAirdrop();
        },3000);
    }

    function showEmailCodeReset() {
        $("#code-send-button").show();
        $("#code-confirmed-loading").hide();
        $("#code-confirmed-button").hide();
    }

    function showRegistrationForm() {
        $("#home-value-props").hide();
        
        $("#home-value-props2").fadeIn();
        $('#register-email').fadeIn();
        $("#register-button-subheadline").hide();
    }

    function showWalletConnectForm() {
        $('#register-email').hide();
        $('#register-wallet').fadeIn();
    }

    function showDownloadNovaModal() {
        openPageModal('downloadinfobox')
    }

    function goToKodaLink() {
        window.open(koda_txn_link);
    }

    function goToHome() {
        window.location.href= "#/home";
    }

    function goToDirectory() {
        window.location.href= "#/teams";
    }

    if(pageSet === false) {
        setPage(true);
        grabEventItem();
        setTimeout(function(){
            startPageModals();
        },500);
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row" id="main-landing-page" style={{position:'relative',width:'100%',minHeight:'100vh',marginBottom:'0px',overflow:'hidden'}}>
        <div className="show-on-small-only hide-on-med-and-up"
                        style={{position:'absolute',width:'100%',bottom:'0vh',left:'-35vw',opacity:'.45'}}>
                            <img src={itemBackdrop} style={{width:'170vw'}} />
                        </div>
        <div className="hide-on-small-only show-on-med-and-up center-align" 
                        style={{position:'absolute',width:'100%',bottom:'-10vh',left:'0px',opacity:'.45'}}>
                            <img src={itemBackdrop} style={{width:'75vw',minWidth:'800px',maxWidth:'1200px'}} />
                        </div>
        <HeaderMintItem />        
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10',marginBottom:'100px'}}>
                <div id="splash-main-buttons">
                    <div className="col s12 center-align">
                        
                        <div className="row" style={{marginTop:'0px',marginBottom:'0px',padding:'0px 20px'}}>
                            <div className="col s12 main-sponsor-icons" style={{padding:'0px'}}>
                                <div className="show-on-large-only hide-on-med-and-down" style={{marginTop:'0px'}}>
                                    <a href="/" className="white-text">
                                        <img id="exr-logo-button" style={{cursor:'pointer'}} title="Polkadot" height="175px" src={itemLogo} />
                                    </a>
                                </div>
                                <div className="show-on-medium-and-down hide-on-large-only" style={{marginTop:'5px'}}>
                                    <a href="/" className="white-text">
                                        <img id="exr-logo-button" style={{cursor:'pointer'}} title="Polkadot" height="150px" src={itemLogo} />
                                    </a>
                                </div>                 
                            </div>
                        </div>
                        <div className="row " style={{marginTop:'5px'}}>
                            <div className="col s12 m8 offset-m2 l4 offset-l4 white-text center-align">
                                <div style={{position:'relative',margin:'0px',padding:'0px',width:'100%',borderRadius:'10px',
                                            marginTop:'-5px',overflow:'hidden',minHeight:'300px'}}>
                                    <img id="main-mint-image" src={itemImage} width="100%" style={{filter:'blur(10px)',borderRadius:'10px'}} />
                                    <span id="main-mint-locked" className="center-align polkadot-green-background polkadot-purple-text" 
                                        style={{fontSize:'24px',width:'120%',padding:'5px 0px 5px 0px',position:'absolute',left:'-10%',bottom:'40%',transform:'rotate(-20deg)'}} 
                                        title="LOCKED">
                                        <span className="material-symbols-outlined polkadot-purple-text" style={{fontSize:'30px',verticalAlign:'middle'}}>lock</span>
                                        <span className="headline-text" style={{verticalAlign:'middle',marginLeft:'10px',fontWeight:'800',letterSpacing:'-1px'}} >LOCKED</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 m10 offset-m1 white-text headline-text" style={{fontSize:'18px',marginBottom:'5px'}}>
                                CONSENSUS 2024
                            </div>
                            <div className="col s12 m10 offset-m1 white-text headline-text show-on-large-only hide-on-med-and-down" style={{fontSize:'40px',fontWeight:'900'}}>
                                {nft_name.toUpperCase()} NFT
                            </div>
                            <div className="col s12 m10 offset-m1 white-text headline-text show-on-medium-and-down hide-on-large-only" style={{fontSize:'30px',fontWeight:'900'}}>
                                {nft_name.toUpperCase()} NFT
                            </div>
                        </div>
                        <div id="mint-splash-button" className="row" style={{marginTop:'35px',marginBottom:'50px'}}>
                            <div id="nft-mint-button" className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align">
                                {collectItemButton}
                            </div>
                            <div className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align">
                                <span id="nft-mint-loading" className="preloader-wrapper small active" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'0px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div id="nft-mint-view" className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align" style={{display:'none',marginTop:'50px',paddingRight:'20px'}}>
                                <span className="white-pink-link" style={{cursor:'pointer'}} onClick={()=>goToKodaLink()}>
                                    <img src="https://media.polkadot.world/global/koda_logo_icon.png" height="30px" />
                                </span>
                                <span className="subheadline-text white-pink-link" style={{fontWeight:'600',fontSize:'18px',cursor:'pointer',verticalAlign:'top'}}
                                     onClick={()=>goToKodaLink()}>
                                    &nbsp;&nbsp;VIEW ON KODA
                                </span>
                            </div>
                        </div>
                        <div id="home-value-props2" className="row" style={{marginTop:'-40px',display:'none'}}>
                            <div className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 white" style={{height:'2px',marginTop:'-10px',marginBottom:'20px'}}>
                            </div>
                            <div className="col s10 offset-s1 white-tex center-align">
                                <div className="subheadline-text"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    DOWNLOAD and CONNECT A <span className="exr-pink-polkadot-text">POLKADOT WALLET</span> TO MINT.
                                </div> 
                            </div>
                        </div>
                        
                        <div id="register-email" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'50px'}}>
                            
                            <div id="register-email-form-title" className="col s10 offset-s1 white-text register-email-forms">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'white'}}>
                                    STEP 1 / 2
                                </span>
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontWeight:'700'}}>
                                    DOWNLOAD WALLET
                                </span>
                            </div>
                            <div id="register-email-form" className="col s10 offset-s1" style={{marginBottom:'20px'}}>
                                <div className="row" style={{marginTop:'20px'}}>
                                    <div className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align">
                                        <div className="row grey lighten-5 black-text" 
                                            style={{display:'flex',flexDirection:'row',padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'0px solid white',cursor:'pointer'}}
                                             title="Show Wallet Connections"
                                             onClick={()=>showDownloadNovaModal()}>
                                            <div className="left" style={{borderRadius:'10px',overflow:'hidden',lineHeight:'1.5',width:'60px',marginLeft:'0px'}}>
                                                <img src={current_wallet_photo} style={{marginTop:'7px',marginLeft:'-7px',borderRadius:'25px'}} height="40px" />
                                            </div>
                                            <div className="left" style={{flex:'1',fontSize:'16px',borderRadius:'10px',lineHeight:'1.5'}}>
                                                <div className="left subheadline-text polkadot-purple-text center-align" style={{width:'100%',marginLeft:'-20px',padding:'0px',marginTop:'6px',marginBottom:'0px',fontWeight:'700'}}>
                                                    NOVA
                                                </div>
                                                <div className="left subheadline-text  polkadot-purple-text center-align" style={{width:'100%',marginLeft:'-20px',padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'12px'}}>
                                                    DOWNLOAD NOW
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:'50px'}}>
                                    <span className="black white-pink-link transparent" 
                                        style={{cursor:'pointer',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 12px 30px',height:'40px',position:'relative'}} 
                                        title="Continue" onClick={()=>showWalletConnectForm()}>
                                        <span id="register-button-arrow2" className="material-symbols-outlined polkadot-pink-text" style={{position:'absolute',left:'5px',top:'3px',fontSize:'35px'}}>
                                            expand_circle_right
                                        </span>
                                        <span className="subheadline-text" style={{margin:"2px 5px 0px 20px",fontSize:'22px',letterSpacing:'1px',verticalAlign:'middle'}}>
                                            CONTINUE
                                        </span>
                                    </span>
                                    <span id="email-sent-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                        style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                        <div className="spinner-layer spinner-blue-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div id="register-wallet" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'50px'}}>
                            <div className="col col s10 offset-s1 white-text">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'white'}}>
                                    STEP 2 / 2
                                </span>
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',fontWeight:'700'}}>
                                    CONNECT YOUR WALLET
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l4" style={{padding:'0px',marginTop:'20px',marginBottom:'20px'}}>
                                <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                                    <div className="row grey lighten-5" style={{display:'flex',flexDirection:'row',padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'1px solid white',cursor:'pointer'}}
                                            onClick={()=>{connectNovaWallet()}} title="Connect Wallet">
                                        <div className="left" style={{marginLeft:'8px',width:'50px',borderRadius:'10px',overflow:'hidden'}}>
                                            <img src="https://media.exiledracers.com/assets/game/builder/Wallet_Connect_Icon_Blue_1710281691551.png" style={{marginTop:'7px',borderRadius:'25px'}} height="44px" />
                                        </div>
                                        <div className="left center-align" style={{flex:'1',fontSize:'14px',borderRadius:'10px'}}>
                                            <div className="left subheadline-text polkadot-purple-text" style={{width:'100%',padding:'0px',marginTop:'8px',marginBottom:'0px',fontWeight:'700'}}>
                                                {walletNetwork}
                                            </div>
                                            <div className="left" style={{width:'100%',padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'16px'}}>
                                                <div className="subheadline-text polkadot-purple-text" onClick={()=>{connectNovaWallet()}}>
                                                    {walletNetworkAddress}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left" style={{marginRight:'10px',width:'50px',borderRadius:'10px',overflow:'hidden'}}>
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="connected-wallet" className="row register-sections" style={{display:'none',marginTop:'-50px',marginBottom:'50px'}}>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px',padding:'0px 20px'}}>
                                <span className="left" style={{display:'none',width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Currently Connected Wallet">
                                    <input id="racerWalletConnected" className="black-bubble-input-round" 
                                        style={{width:'100%',fontSize:'20px',borderColor:'black'}} disabled />
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                                <span id="wallet-save-button" className="black white-pink-link transparent" 
                                        style={{cursor:'pointer',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Continue to Mint" onClick={()=>submitWalletSaved()}>
                                    <span id="register-button-arrow2" className="material-symbols-outlined polkadot-pink-text" style={{position:'absolute',left:'5px',top:'3px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text" style={{margin:"2px 5px 0px 20px",fontSize:'22px',letterSpacing:'1px',verticalAlign:'middle'}}>
                                        CONTINUE TO MINT
                                    </span>
                                </span>
                                <span id="wallet-save-loading" className="preloader-wrapper small active" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div style={{position:'absolute',left:'0px',bottom:'20px',width:'100%'}}>
                <div className="col s12 m6 offset-m3 center-align">
                    <div className="left subheadline-text white-pink-link" style={{width:'33.3%',fontWeight:'600',cursor:'pointer',marginTop:'15px'}}
                        onClick={()=>goToHome()}>
                        VIEW POAPS
                    </div> 
                    <div className="left subheadline-text white-pink-link center-align" style={{width:'33.3%',cursor:'pointer'}}
                        onClick={()=>goToHome()}>
                        <img src='https://media.exiledracers.com/assets/game/builder/Polkadot_Logo_1699372034234.png' height="50px" />
                    </div> 
                    <div className="right subheadline-text white-pink-link" style={{width:'33.3%',fontWeight:'600',cursor:'pointer',marginTop:'15px'}}
                        onClick={()=>goToDirectory()}>
                        DIRECTORY
                    </div>
                </div>
            </div>
        </div>
        <DownloadNovaModal />
    </div>);
};