import {axiosInstance } from "../utils";
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers/react';
import $ from "jquery";

export function validateEmailText(emailtext:string) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (emailtext.match(validRegex)) {
      return 'pass';
    } else {
      return 'fail';
    }
}

export async function sendEventEmailCode(raceremail:string) {
    var addData:any = new Object();
    addData.specEmail = encodeURIComponent(raceremail);

    var email_sent_response = await axiosInstance.post('member/event_email_add/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                return {'status':'email_sent'};
            } else {
                return {'status':'email_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return email_sent_response;
}

export async function checkEventEmailCode(raceremail:string,racercode:string,exreventid:number) {
    
    var addData:any = new Object();
    addData.specEmail = encodeURIComponent(raceremail);
    addData.specCode = encodeURIComponent(racercode);
    addData.specEvent = encodeURIComponent(exreventid);

    var email_code_check_response = await axiosInstance.post('member/event_email_code_check/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                if(save_result[0]['status'] == 'expired') {
                    return {'status':'email_code_expired'};
                } else if(save_result[0]['status'] == 'pending') {
                    return {'status':'email_code_wrong'};
                } else if (save_result[0]['status'] == 'approved_waitroom') {
                    return {'status':'confirmed_waitroom'};
                } else {
                    // Existing User - Approve Code
                    let userid = save_result[0]['user_id'];
                    let racerid = save_result[0]['racer_id'];
                    let spectatorid = save_result[0]['spectator_id'];
                    let logintime = save_result[0]['time'];
                    let logintoken = save_result[0]['token'];
                    let walletid = save_result[0]['wallet_id'];

                    var login_creds = {
                        'userid':userid,
                        'racerid':racerid,
                        'spectatorid':spectatorid,
                        'logintime':logintime,
                        'logintoken':logintoken,
                        'walletid':walletid}
                    return {'status':'confirmed_existing','login_info':login_creds};
                }
            } else {
                return {'status':'lookup_error'};
            }
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return email_code_check_response;
}

export async function addSpectatorInfo(raceremail:string,racerhandle:string,eventid:number) {
    var racertwitter = ''
    var racerdiscord = ''
    var addData:any = new Object();
    addData.specEmail = encodeURIComponent(raceremail);
    addData.specHandle = encodeURIComponent(racerhandle);
    addData.specTwitter = encodeURIComponent(racertwitter);
    addData.specDiscord = encodeURIComponent(racerdiscord);
    if(eventid > 0) {
        addData.specSource = 'event';
        addData.specEvent = encodeURIComponent(eventid);
    } else {
        addData.specSource = 'homepage';
    }
    
    let new_user_info = await axiosInstance.post('member/waitroom_spectator_add/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                let userid = save_result[0]['user_id'];
                let racerid = save_result[0]['racer_id'];
                let spectatorid = save_result[0]['spectator_id'];
                let logintime = save_result[0]['time'];
                let logintoken = save_result[0]['token'];
                let walletid = save_result[0]['wallet_id'];

                var login_creds = {
                    'userid':userid,
                    'racerid':racerid,
                    'spectatorid':spectatorid,
                    'logintime':logintime,
                    'logintoken':logintoken,
                    'walletid':walletid}

                return {'status':'confirmed','login_info':login_creds};

            } else {
                return {'status':'update_error'};
            }
        } else {
            return {'status':'lookup_error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return new_user_info;
}

export async function addEventSignupWallet(apikey:string, eventid:number,walletaddress:string) {

    var addData:any = new Object();
    addData.apikey = encodeURIComponent(apikey);
    addData.event_id = eventid;
    addData.wallet = encodeURIComponent(walletaddress);

    let wallet_info = axiosInstance.post('event/user_update/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var save_result = result.data;
            if(save_result.length > 0) {
                if(save_result[0]['status'] == 'success') {
                    let user_id = save_result[0]['user'];
                    let event_user = save_result[0]['event_user'];
                    let logintime = save_result[0]['time'];
                    let logintoken = save_result[0]['token'];
                    
                    var login_creds = {
                        'user':user_id,
                        'event_user':event_user,
                        'logintime':logintime,
                        'logintoken':logintoken,
                        'wallet':walletaddress}
    
                    return {'status':'confirmed','login_info':login_creds};
                } else {
                    return {'status':'no_account'};
                }
            } else {
                return {'status':'update_error'};
            }
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return wallet_info;
}



export async function createRedirectToken(userid:any) {
    let stringfront = (Math.random() + 1).toString(36).substring(7);
    let stringend = (Math.random() + 1).toString(36).substring(7);
    let hashed_userid = stringfront+'u7t'+userid+'b4k'+stringfront;

    var addData:any = new Object();
    addData.redirectToken = encodeURIComponent(hashed_userid);

    let redirect_token = axiosInstance.post('member/redirect_link/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var info_result = result.data;
            if(info_result[0]['status'] == 'new') {
                return {'status':'new','token':info_result[0]['token']};
            } else {
                return {'status':'error'};
            }
        } else {
            return {'status':'error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return redirect_token;
}

export async function getEventUserInfo(userid:number,exreventid:number) {

    var addData:any = new Object();
    addData.specID = encodeURIComponent(userid);
    addData.specEvent = encodeURIComponent(exreventid);

    let event_info = axiosInstance.post('member/event_info/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var info_result = result.data;
            if(info_result.length > 0) {
                return {'status':'confirmed','event_info':info_result[0]};
            } else {
                return {'status':'error'};
            }
        } else {
            return {'status':'error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return event_info;
}

export async function addEventUserAction(userid:number,exreventid:number,actiontype:string,actionid='') {

    var addData:any = new Object();
    addData.specID = encodeURIComponent(userid);
    addData.specEvent = encodeURIComponent(exreventid);
    addData.specAction = encodeURIComponent(actiontype);
    addData.specActionID = encodeURIComponent(actionid);

    let event_info = axiosInstance.post('member/event_action/',addData).then(result => {
        if(Object.keys(result.data).length > 0) {
            var info_result = result.data;
            return {'status':'confirmed'};
        } else {
            return {'status':'error'};
        }
    }).catch(error => {
        return {'status':'server_error'};
    });

    return event_info;
}


export async function WalletConnectModalSetup() {
    // 1. Get projectId
    const projectId = '16f92b2d5656feab7242fad0cf8cc20f'

    // 2. Set chains
    const wcMainnet = {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com'
    }

    // 3. Create a metadata object
    const wcMetadata = {
        name: 'Polkadot Go',
        description: 'Polkadot Go - POAP Hunt',
        url: 'https://www.polkadot.world/',
        //url: 'http://localhost:3001',
        icons: ['https://base.exiledracers.com/favicon-96x96.png']
    }

    // 4. Create Ethers config
    const ethersConfig = defaultConfig({
        metadata: wcMetadata,
        /*Optional*/
        enableEIP6963: true, // true by default
        enableInjected: true, // true by default
        enableCoinbase: true, // true by default
        rpcUrl: '...', // used for the Coinbase SDK
        defaultChainId: 1, // used for the Coinbase SDK
    });

    // 5. Create a Web3Modal instance
    var wcModal = createWeb3Modal({
        ethersConfig,
        chains: [wcMainnet],
        projectId,
        enableAnalytics: true // Optional - defaults to your Cloud configuration
    });

    let wcConnection = await wcModal.open();
    return wcModal.getAddress();
}

export function useWalletStatus() {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    return { address, chainId, isConnected };
}