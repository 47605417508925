const initialState = {
  loading: false,
  page_hash: (window.location.hash[window.location.hash.length - 1] === '/') ? window.location.hash.slice(0, -1) : window.location.hash,
  page_loaded: false, 
  live_hosts: ['www.polkadot.world']
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PAGE_LOADED":
      return {
        ...state,
        page_loaded: true,
      };
    
    case "UPDATE_PAGE_HASH":
      return {
        ...state,
        page_hash: action.payload,
      };
    
    default:
      return state;
  }
};

export default appReducer;
