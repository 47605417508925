import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {closeAllModals} from "../../../utils";
import $ from "jquery";

const DownloadNovaModal = (props:any) => {
    const dispatch = useDispatch();
    let apple_download_link = 'https://apps.apple.com/app/nova-polkadot-kusama-wallet/id1597119355';
    let google_download_link = 'https://play.google.com/store/apps/details?id=io.novafoundation.nova.market';

    return (<div id="downloadinfobox" className="modal page-modal page-modal-download polkadot-pink-background">
            <div className="modal-content">
                <div className="row" style={{marginBottom:'5px',position:'relative'}}>
                    <div style={{position:'absolute',right:'-10px',top:'-10px'}} title="Close" onClick={()=>closeAllModals()}>
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                    </div>
                    <div className="col s12 headline-text center-align" style={{fontSize:"24px",marginTop:"20px",padding:"0px",fontWeight:'700'}}>
                        DOWNLOAD NOVA
                    </div>
                    <div className="col s12 subheadline-text center-align" style={{marginTop:'40px',fontSize:'16px'}}>
                        <a target="_blank" href={apple_download_link}>
                            <img alt='Download on the App Store' src='css/images/apple-store-badge.svg' width="80%" />
                        </a>
                    </div>
                    <div className="col s12 subheadline-text center-align" style={{marginTop:'20px',fontSize:'16px',marginBottom:'20px'}}>
                        <a target="_blank" href={google_download_link}>
                            <img alt='Get It On Google Play'  src='css/images/google-play-badge.png' width="95%" />
                        </a>
                    </div>
                    
                </div>
            </div>
        </div>
  );
};

export default DownloadNovaModal;