export const setReady = () => {
  console.log("setting ready");
  return async (dispatch) => {
    dispatch({
      type: "SET_IS_READY",
    });
  };
};

export const setLoginWallet = (walletID) => {
  localStorage.setItem('dotWorldWallet', walletID);
  return async (dispatch) => {
    dispatch({
      type: "SET_ACCOUNT_ADDRESS",
      payload: walletID,
    });
  };
};

export const setUserSignOn = (userID, eventUserID, loginToken, loginTime) => {
  let tokenval = userID+"."+eventUserID;
  localStorage.setItem('dotWorldTokenID', loginToken);
  localStorage.setItem('dotWorldTokenTime', loginTime);
  localStorage.setItem('dotWorldTokenVal', tokenval);
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_SIGNON",
      payload: {user_id: userID, event_user_id: eventUserID, token: loginToken, token_time: loginTime},
    });
  };
};

export const clearUserSignOn = () => {
  localStorage.removeItem('dotWorldTokenID');
  localStorage.removeItem('dotWorldTokenTime');
  localStorage.removeItem('dotWorldTokenVal');
  localStorage.removeItem('dotWorldWallet');
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_SIGNON",
      payload: {user_id: 0, event_user_id: 0, token: '', token_time: ''},
    });
    dispatch({
      type: "SET_ACCOUNT_ADDRESS",
      payload: '',
    });
  };
};