const initialState = {
  hasAccount: false,
  loggedIn: false,
  status: null,
  walletID: localStorage.getItem('dotWorldWallet')||'',
  userID: (localStorage.getItem('dotWorldTokenVal')? (localStorage.getItem('dotWorldTokenVal').indexOf('.') > 0 ? localStorage.getItem('dotWorldTokenVal').split('.')[0] : 0): 0),
  eventUserID:  (localStorage.getItem('dotWorldTokenVal')? (localStorage.getItem('dotWorldTokenVal').indexOf('.') > 0 ? localStorage.getItem('dotWorldTokenVal').split('.')[1] : 0): 0),
  loginTime: localStorage.getItem('exrTokenTime')||'',
  loginToken: localStorage.getItem('exrTokenID')||'',
  infoboxHowTo: 1
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCOUNT_ADDRESS":
      return {
        ...state,
        walletID: action.payload,
      };

    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };

    case "SET_USER_SIGNON":
        return {
          ...state,
          userID: action.payload.user_id,
          eventUserID: action.payload.event_user_id,
          loginToken: action.payload.token,
          loginTime: action.payload.token_time,
        };

    default:
      return state;
  }
};

export default accountReducer;
