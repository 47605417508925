import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {useWeb3ModalAccount } from '@web3modal/ethers/react';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, ethers } from 'ethers';
import UniversalProvider from '@walletconnect/universal-provider'
import { WalletConnectModal } from '@walletconnect/modal';
import { Magic } from 'magic-sdk';
import { PolkadotExtension } from '@magic-ext/polkadot';

import { setUserSignOn, setLoginWallet } from "../../../../../redux/Account/actions";
import {validateEmailText, sendEventEmailCode, checkEventEmailCode, addEventSignupWallet} from "../../../../../utils/signon";
import {getEventUserInfo, addEventUserAction, WalletConnectModalSetup, useWalletStatus} from "../../../../../utils/signon";
import {axiosInstance, startPageModals, openPageModal } from "../../../../../utils";
import {HeaderMintItem, DownloadNovaModal} from "../../../../organisms";

import $ from "jquery";

let current_user_id = 0; 
let current_wallet_address = '';

export const SplashPage = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { userID, eventUserID, walletID } = useSelector((state:any) => state.account);
    const { address, chainId, isConnected } = useWalletStatus();
    const { walletProvider } = useWeb3ModalProvider();

    const [pageSet, setPage] = useState(false);
    const [email, setEmail] = useState("");
    const [current_wallet, setCurrentWallet] = useState("");
    const [walletNetwork, setWalletNetwork] = useState('CONNECT');
    const [walletNetworkAddress, setWalletNetworkAddress] = useState('Not Connected');
    const [registration_level, setRegistrationlevel] = useState('REGISTRATION COMPLETE');

    const [publicAddress, setPublicAddress] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userMetadata, setUserMetadata] = useState({});

    /* Event ID */
    var partnereventid = 1;
    var apikey = 'h2zcswrivdly7d6v9uo6';
    let signup_user_id = 0;
    let logo_image = 'https://media.exiledracers.com/assets/game/builder/Polkadot_Logo_1699372034234.png';
    logo_image = 'https://media.polkadot.world/global/polkadot_go_logo.png';
    let current_wallet_photo = "css/images/nova_icon_color.png";


    function checkForWalletAddress() {
        let newaddress = props.wcModal.getAddress();
        let walletconnected = props.wcModal.getIsConnected();
        if((newaddress !== undefined)&&(walletconnected)&&((newaddress != current_wallet_address)||(walletNetwork == 'CONNECT'))) {
            current_wallet_address = newaddress;
            var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-10);
            
            dispatch(setLoginWallet(current_wallet_address));
            let newnetwork = props.wcModal.getWalletProvider();
            let newchain = props.wcModal.getChainId();
            setWalletNetwork(obf_wallet);
            setWalletNetworkAddress('Wallet Connected');
            $("#racerWalletConnected").val(obf_wallet);
            $("#connected-wallet").fadeIn();

            setTimeout(function(){
                checkForWalletAddress();
            },2000);
        } else {
            if((!walletconnected)&&(walletNetwork != 'CONNECT')) {
                setWalletNetwork('CONNECT');
                setWalletNetworkAddress('Not Connected');
                $("#racerWalletConnected").val('');
                $("#connected-wallet").fadeOut();
            }
            setTimeout(function(){
                checkForWalletAddress();
            },2000);
        }
    }

    async function submitEmailAddress() {
        var raceremail = (document.getElementById("racerEmailAddress") as HTMLInputElement).value.trim();

        var addData:any = new Object();
        addData.specEmail = encodeURIComponent(raceremail);

        if(raceremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else {
            $("#email-send-button").hide();
            $("#email-sent-loading").show();
            if(userID > 10000) {
                showEmailLoggedIn();
            } else {
                let email_send = await sendEventEmailCode(raceremail);
                if(email_send['status'] == 'email_sent') {
                    setEmail(raceremail);
                    showEmailSendSent();
                } else {
                    showEmailSendReset();
                    alert('Something Went Wrong. Update and Try Again.');
                }
            }
        }
    }

    async function submitEmailCode() {
        var raceremail = (document.getElementById("racerEmailAddress") as HTMLInputElement).value.trim();
        var racercode = (document.getElementById("racerEmailCode") as HTMLInputElement).value.trim();
        
        var addData:any = new Object();
        addData.specEmail = encodeURIComponent(raceremail);
        addData.specCode = encodeURIComponent(racercode);
        addData.specEvent = encodeURIComponent(partnereventid);

        if(raceremail.length <= 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(raceremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if (racercode.length != 6) {
            alert('Add a Valid Email Code');
        } else {
            $("#code-send-button").hide();
            $("#code-confirmed-loading").show();
            let email_code_check:any = await checkEventEmailCode(raceremail,racercode,partnereventid);
            if(email_code_check) {
                if(email_code_check['status'] == 'email_code_expired') {
                    showEmailCodeReset();
                    alert('Your Confirmation Code Expired. Register Again To Get a New Code.');
                } else if(email_code_check['status'] == 'email_code_wrong') {
                    showEmailCodeReset();
                    alert('Invalid Code. Check Your Code and Enter it Again');
                } else if (email_code_check['status'] == 'confirmed_waitroom') {
                    // New User - Add to Users Table
                    setRegistrationlevel('REGISTERED TO PREDICT');
    
                } else if (email_code_check['status'] == 'confirmed_existing') {
                    // Existing User - Approve Code
                    let userid = email_code_check['login_info']['userid'];
                    let event_user = email_code_check['login_info']['event_user'];
                    let logintime = email_code_check['login_info']['logintime'];
                    let logintoken = email_code_check['login_info']['logintoken'];
                    let walletid = email_code_check['login_info']['wallet'];
                    
                    // Login to Site
                    current_user_id = userid;
                    signup_user_id = userid;
                    dispatch(setUserSignOn(userid,event_user,logintoken,logintime));
    
                    if(walletid != null) {
                        if(walletid.length > 10) {
                            // Existing User - With Wallet
                            current_wallet_address = walletid;
                            var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-15);
                            $("#racerWalletConnected").val(obf_wallet);
                                
                            dispatch(setLoginWallet(current_wallet_address));
                            setCurrentWallet(current_wallet_address);
                            //showWalletSaved();
                            showEmailCodeApproved();
                        } else {
                            // Existing User - No Wallet - Prompt To Add
                            showEmailCodeApproved();
                            setRegistrationlevel('REGISTERED TO PREDICT');
                        }
                    } else {
                        // Existing User - No Wallet - Prompt To Add
                        showEmailCodeApproved();
                        setRegistrationlevel('REGISTERED TO PREDICT');
                    }
                    
                } else {
                    showEmailCodeReset();
                    alert('Whoops! Something Went Wrong. Update and Try Again');
                }
            }
        }
    }

    async function submitWalletSaved() {
        if(current_wallet_address.length < 5) {
            alert('Connect A Wallet');
        } else {
            $("#wallet-save-button").hide();
            $("#wallet-save-loading").show();
            let wallet_info:any = await addEventSignupWallet(apikey, partnereventid, current_wallet_address);

            if(wallet_info) {
                if(wallet_info['status'] == 'duplicate_wallet') {
                    // $("#wallet-save-loading").hide();
                    // $("#wallet-save-button").show();
                    // alert('This Wallet Is Already Registered. Connect a Different Wallet Later or Login to your Account.');
                    showWalletSaved();
                } else if(wallet_info['status'] == 'no_account') {
                    $("#wallet-save-loading").hide();
                    $("#wallet-save-button").show();
                    alert('This Email Account is not Registered. Go Back and Complete the Signup Process.');
                } else if(wallet_info['status'] == 'confirmed') {
                    let userid = wallet_info['login_info']['user'];
                    let event_user = wallet_info['login_info']['event_user'];
                    let wallet = wallet_info['login_info']['wallet'];
                    let logintime = wallet_info['login_info']['logintime'];
                    let logintoken = wallet_info['login_info']['logintoken'];
    
                    dispatch(setUserSignOn(userid,event_user,logintoken,logintime));
                    dispatch(setLoginWallet(current_wallet_address));
    
                    showWalletSaved();
                } else {
                    $("#wallet-save-loading").hide();
                    $("#wallet-save-button").show();
                    alert('Whoops! Something Went Wrong. Update and Try Again');
                }
            }
            
        }
    }

    async function connectWalletProvider(wallet_type:string) {
        let wallet_connection;

        let wcConnection = await props.wcModal.open();
        checkForWalletAddress();
    }

    async function connectNovaWallet() {
        
        const projectId = '95ff87bbe3214c976bf1698c3f6abe6a';
        const provider = await UniversalProvider.init({
          projectId: projectId,
          relayUrl: 'wss://relay.walletconnect.com'
        }); 
        const params = {
          requiredNamespaces: {
            polkadot: {
              methods: ['polkadot_signTransaction', 'polkadot_signMessage'],
              chains: [
                'polkadot:91b171bb158e2d3848fa23a9f1c25182', // polkadot
              ],
              events: ["chainChanged", "accountsChanged"]
            }
          }
          
        }

        const { uri, approval } = await provider.client.connect(params);
        const walletConnectModal = new WalletConnectModal({
          projectId: projectId,
          themeMode: 'dark',
          explorerRecommendedWalletIds: [
            '43fd1a0aeb90df53ade012cca36692a46d265f0b99b7561e645af42d752edb92',
            '9ce87712b99b3eb57396cc8621db8900ac983c712236f48fb70ad28760be3f6a',
          ],
          explorerExcludedWalletIds: 'ALL',
        });

        
    
        if (uri) {
          walletConnectModal.openModal({ uri })
        }
        // await session approval from the wallet app
        const walletConnectSession = await approval();
      
        const walletConnectAccount = Object.values(walletConnectSession.namespaces)
          .map(namespace => namespace.accounts)
          .flat();


        provider.on("proposal_expire", (event:any) => {
            // proposal expired and any modal displaying it should be removed
            event.preventDefault();
            const { id } = event;
            if(walletConnectModal) {
                walletConnectModal.closeModal();
            }
        });

        // grab account addresses from CAIP account formatted accounts
        const accounts = walletConnectAccount.map(wcAccount => {
          const address = wcAccount.split(':')[2]
          let newaddress = address;
          if((newaddress !== undefined)&&((newaddress != current_wallet_address)||(walletNetwork == 'CONNECT'))) {
              current_wallet_address = newaddress;
              var obf_wallet = current_wallet_address.slice(0,6)+'...'+current_wallet_address.slice(-10);
              
              dispatch(setLoginWallet(current_wallet_address));
              setWalletNetwork(obf_wallet);
              setWalletNetworkAddress('Wallet Connected');
              $("#racerWalletConnected").val(obf_wallet);
              $("#connected-wallet").fadeIn();
  
              return address;
          } 
        });
        walletConnectModal.closeModal();
    }

    function showEmailSendSent() {
        setTimeout(function(){
            $("#register-email").hide();

            $("#email-sent-text").fadeIn();
            $("#register-email-code").fadeIn();
        },3000);
    }

    function showEmailSendReset() {
        $("#email-send-button").show();
        $("#email-sent-loading").hide();
        $("#email-sent-button").hide();
    }

    function showEmailLoggedIn() {

        setTimeout(function(){
            $("#email-send-button").hide();
            $("#email-sent-loading").hide();
        },3000);
    }

    function showEmailCodeApproved() {
        setTimeout(function(){
            $("#register-email-code").hide();
            $("#email-sent-text").hide();

            $("#email-confirmed-text").fadeIn();
            $("#register-wallet").fadeIn();
        },3000);
    }

    

    function showConnectedWalletForm() {
        $("#connected-wallet").fadeIn();
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    }

    function showWalletSaved() {

        setTimeout(function(){
            $("#wallet-save-loading").hide();
            $("#register-email-code").hide();
            $("#email-sent-text").hide();
            $("#register-wallet").hide();
            $("#connected-wallet").hide();
        },3000);

        setTimeout(function(){
            window.location.href="#/home";
        },4000);
        
    }

    function showEmailCodeReset() {
        $("#code-send-button").show();
        $("#code-confirmed-loading").hide();
        $("#code-confirmed-button").hide();
    }

    function showRegistrationForm() {
        $("#register-button").hide();
        $("#home-value-props").hide();
        
        $("#home-value-props2").fadeIn();
        $('#register-email').fadeIn();
        $("#register-button-subheadline").hide();
    }

    function showWalletConnectForm() {
        $('#register-email').hide();
        $('#register-wallet').fadeIn();
    }

    function showDownloadNovaModal() {
        openPageModal('downloadinfobox')
    }

    if(pageSet === false) {
        setPage(true);
        if(eventUserID > 0) {
            window.location.href="#/home";
        }
        setTimeout(function(){
            startPageModals();
        },500);
        // loadLeagueUserInfo();

    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto',overflowY:'hidden'}}>
        <HeaderMintItem />
        <div className="row" style={{padding:'0px',margin:'0px',position:'relative',minHeight:'100vh',marginBottom:'0px',overflow:'hidden'}}>
            <div className="show-on-small-only hide-on-med-and-up"
                        style={{position:'absolute',height:'100%',width:'100%',bottom:'0px',left:'0px',opacity:'.25',
                            background:'url(https://media.polkadot.world/global/polkadot_main_backdrop.png)',backgroundSize:'stretch',backgroundPosition:'center'
                        }}></div>
                <div className="hide-on-small-only show-on-med-and-up center-align"
                        style={{position:'absolute',height:'100%',width:'100%',bottom:'0px',left:'0px',opacity:'.25',
                            background:'url(https://media.polkadot.world/global/polkadot_main_backdrop.png)',backgroundSize:'stretch',backgroundPosition:'center'
                        }}></div>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10'}}>
                <div id="splash-main-buttons">
                    <div className="col s12 center-align">
                        <div className="row" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                            <div className="col s12 main-sponsor-icons" style={{padding:'0px'}}>
                                <div className="show-on-large-only hide-on-med-and-down" style={{marginTop:'5px'}}>
                                    <a href="/" className="white-text">
                                        <img id="exr-logo-button" style={{cursor:'pointer'}} title="Polkadot" height="120px" src={logo_image} />
                                    </a>
                                </div>
                                <div className="show-on-medium-and-down hide-on-large-only" style={{marginTop:'50px'}}>
                                    <a href="/" className="white-text">
                                        <img id="exr-logo-button" title="Polkadot" height="120px" src={logo_image} style={{cursor:'pointer'}} />
                                    </a>
                                </div>                 
                            </div>
                        </div>
                        <div className="row " style={{marginTop:'30px'}}>
                            <div className="col s12 m10 offset-m1 white-text">
                                <div className="headline-text center-align white-text" style={{fontSize:'26px',lineHeight:'1.25',fontWeight:'900'}}>
                                   CONSENSUS 2024
                                </div>
                            </div>
                        </div>
                        <div id="home-value-props" className="row" style={{marginTop:'30px'}}>
                            <div className="col s10 offset-s1 white-text show-on-medium-and-up hide-on-small-only">
                                <div className="subheadline-text"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'white',letterSpacing:'2px'}} >
                                    — GET A POLKADOT WALLET<br/>
                                    — LEARN ABOUT PROJECTS<br/>
                                    — COLLECT ALL THE POAPS<br/>
                                    — WIN CHANCE AT PRIZES
                                </div> 
                            </div>
                            <div className="col s12 white-text show-on-small-only hide-on-med-and-up">
                                <div className="subheadline-text center"
                                    style={{fontSize:'16px',textTransform:'uppercase',color:'white',letterSpacing:'2px'}} >
                                    — GET A POLKADOT WALLET<br/>
                                    — LEARN ABOUT PROJECTS<br/>
                                    — COLLECT ALL THE POAPS<br/>
                                    — WIN CHANCE AT PRIZES
                                </div> 
                            </div>
                        </div>
                        <div id="home-value-props2" className="row" style={{marginTop:'30px',display:'none'}}>
                            <div className="col s10 offset-s1 white-tex center-align">
                                <div className="subheadline-text"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'white',letterSpacing:'2px'}} >
                                    DOWNLOAD and CONNECT A <span className="polkadot-neon-text">POLKADOT WALLET</span> TO JOIN.
                                </div> 
                            </div>
                        </div>
                        <div id="register-email" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            
                            <div id="register-email-form-title" className="col s10 offset-s1 white-text register-email-forms">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'white'}}>
                                    STEP 1 / 2
                                </span>
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontWeight:'700'}}>
                                    DOWNLOAD WALLET
                                </span>
                            </div>
                            <div id="register-email-form" className="col s10 offset-s1" style={{marginBottom:'20px'}}>
                                <div className="row" style={{marginTop:'20px'}}>
                                    <div className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align">
                                        <div className="row grey lighten-5 black-text" 
                                            style={{display:'flex',flexDirection:'row',padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'0px solid white',cursor:'pointer'}}
                                             title="Download Nova Wallet"
                                             onClick={()=>showDownloadNovaModal()}>
                                            <div className="left" style={{borderRadius:'10px',overflow:'hidden',lineHeight:'1.5',width:'60px',marginLeft:'0px'}}>
                                                <img src={current_wallet_photo} style={{marginTop:'7px',marginLeft:'-7px',borderRadius:'25px'}} height="40px" />
                                            </div>
                                            <div className="left" style={{flex:'1',fontSize:'16px',borderRadius:'10px',lineHeight:'1.5'}}>
                                                <div className="left subheadline-text polkadot-purple-text center-align" style={{width:'100%',marginLeft:'-20px',padding:'0px',marginTop:'6px',marginBottom:'0px',fontWeight:'700'}}>
                                                    NOVA
                                                </div>
                                                <div className="left subheadline-text  polkadot-purple-text center-align" style={{width:'100%',marginLeft:'-20px',padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'12px'}}>
                                                    DOWNLOAD NOW
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop:'60px'}}>
                                    <span className="black white-pink-link transparent" 
                                        style={{cursor:'pointer',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 12px 30px',height:'40px',position:'relative'}} 
                                        title="Continue To Connect Wallet" onClick={()=>showWalletConnectForm()}>
                                        <span id="register-button-arrow2" className="material-symbols-outlined polkadot-pink-text" style={{position:'absolute',left:'5px',top:'3px',fontSize:'35px'}}>
                                            expand_circle_right
                                        </span>
                                        <span className="subheadline-text" style={{margin:"2px 5px 0px 20px",fontSize:'22px',letterSpacing:'1px',verticalAlign:'middle'}}>
                                            CONTINUE
                                        </span>
                                    </span>
                                    <span id="email-sent-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                        style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                        <div className="spinner-layer spinner-blue-only">
                                            <div className="circle-clipper left">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="gap-patch">
                                                <div className="circle"></div>
                                            </div>
                                            <div className="circle-clipper right">
                                                <div className="circle"></div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div id="register-wallet" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            <div className="col col s10 offset-s1 white-text">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'white'}}>
                                    STEP 2 / 2
                                </span>
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',fontWeight:'700'}}>
                                    CONNECT YOUR WALLET
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l4" style={{padding:'0px',marginTop:'20px',marginBottom:'20px'}}>
                                <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'20px',padding:'0px 20px'}}>
                                    <div className="row grey lighten-5" style={{display:'flex',flexDirection:'row',padding:'0px 0px',marginBottom:'0px',borderRadius:'25px', border:'1px solid white',cursor:'pointer'}}
                                            onClick={()=>{connectNovaWallet()}} title="Connect Wallet">
                                        <div className="left" style={{marginLeft:'8px',width:'50px',borderRadius:'10px',overflow:'hidden'}} onClick={()=>{connectNovaWallet()}}>
                                            <img src="https://media.exiledracers.com/assets/game/builder/Wallet_Connect_Icon_Blue_1710281691551.png" style={{marginTop:'7px',borderRadius:'25px'}} height="44px" />
                                        </div>
                                        <div className="left center-align" style={{flex:'1',fontSize:'14px',borderRadius:'10px'}} onClick={()=>{connectNovaWallet()}}>
                                            <div className="left subheadline-text polkadot-purple-text" onClick={()=>{connectNovaWallet()}} style={{width:'100%',padding:'0px',marginTop:'8px',marginBottom:'0px',fontWeight:'700'}}>
                                                {walletNetwork}
                                            </div>
                                            <div className="left" style={{width:'100%',padding:'0px',marginTop:'-2px',marginBottom:'5px',fontSize:'16px'}} onClick={()=>{connectNovaWallet()}}>
                                                <div className="subheadline-text polkadot-purple-text" onClick={()=>{connectNovaWallet()}}>
                                                    {walletNetworkAddress}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="left" style={{marginRight:'10px',width:'50px',borderRadius:'10px',overflow:'hidden'}}>
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="connected-wallet" className="row register-sections" style={{display:'none',marginTop:'0px',marginBottom:'0px'}}>
                            <div className="col s10 offset-s1 m7 offset-m1 l4 offset-l1" style={{marginBottom:'20px',padding:'0px 20px'}}>
                                <span className="left" style={{display:'none',width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Currently Connected Wallet">
                                    <input id="racerWalletConnected" className="black-bubble-input-round" 
                                        style={{width:'100%',fontSize:'20px',borderColor:'black'}} disabled />
                                </span>
                            </div>
                            <div className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align" style={{marginTop:'20px',marginBottom:'20px',padding:'0px 20px'}}>
                                <span id="wallet-save-button" className="black white-pink-link transparent" 
                                        style={{cursor:'pointer',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 30px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Continue To Login" onClick={()=>submitWalletSaved()}>
                                    <span id="register-button-arrow2" className="material-symbols-outlined polkadot-pink-text" style={{position:'absolute',left:'5px',top:'3px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text" style={{margin:"2px 5px 0px 20px",fontSize:'22px',letterSpacing:'1px',verticalAlign:'middle'}}>
                                        CONTINUE
                                    </span>
                                </span>
                                <span id="wallet-save-loading" className="preloader-wrapper small active" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div id="register-button" className="row" style={{position:'absolute',bottom:'20vh',left:'0px',width:'100%'}}>
                <div className="col s10 offset-s1 m6 offset-m3 l4 offset-l4 center-align">
                    <span className="center-align pink-green-button" 
                        style={{cursor:'pointer',width:'auto',fontSize:'20px',borderRadius:'10px',padding:'10px 30px 15px 30px',height:'40px',position:'relative'}} 
                        title="Register For Polkadot Go" onClick={()=>showRegistrationForm()}>
                        <span className="subheadline-text" style={{margin:"2px 5px 2px 5px",fontSize:'22px',letterSpacing:'1px',verticalAlign:'middle',fontWeight:'700'}}>
                            GET STARTED
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <DownloadNovaModal />
    </div>);
};