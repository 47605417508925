import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateLocation } from "../../../redux/App/actions";
import { clearUserSignOn } from "../../../redux/Account/actions";
import {startPageModals} from "../../../utils";

import $ from "jquery";
let web3;
let user_type = 'public';
let homelink = '';
let teamslink = '';

const HeaderMintItem = () => {
    const dispatch = useDispatch();
    const { userID, eventUserID, walletID } = useSelector((state:any) => state.account);
    const [headerSet, setHeader] = useState(false);
    const [leftHeader, setLeftHeader] = useState('');
    const [rightHeader, setRightHeader] = useState('');
    const [mobileNav, setMobileNav] = useState('');

    let logo_image = 'https://media.exiledracers.com/assets/game/builder/Polkadot_Logo_1699372034234.png';
    logo_image = 'https://media.polkadot.world/global/polkadot_go_logo.png';
    //logo_image = 'css/images/polkadotgo_logo_tmp.png'

    function goHome() {
        window.location.href= '/#/home';
    }
    function goToDirectory() {
        window.location.href= '/#/teams';
    }
    function logoutUser() {
        window.location.href= '/';
    }

    function loadMobileNav() {
        var nav_elems = document.querySelectorAll('.sidenav');
        var nav_instances = window.M.Sidenav.init(nav_elems,{'edge':'right'});
    }

    if(window.location.hash.indexOf('teams') >= 0) {
        homelink = '';
        teamslink = '';
    } else {
        homelink = '';
        teamslink = '';
    }

    const rightsidelinks = <><div className="top-nav-header-right-item-full white-neon-link" style={{lineHeight:'1.5'}}  onClick={()=>logoutUser()}>
                                <div className="top-nav-header-item-text">
                                    LOGOUT
                                </div>
                            </div>
                            
                            <div className={"top-nav-header-right-item-full white-neon-link"+teamslink} style={{lineHeight:'1.5'}}  onClick={()=>goToDirectory()}>
                                <div className="top-nav-header-item-text">
                                    DIRECTORY
                                </div>
                            </div>
                            <div className={"top-nav-header-right-item-full white-neon-link"+homelink} style={{lineHeight:'1.5'}}  onClick={()=>goHome()}>
                                <div className="top-nav-header-item-text">
                                    HOME
                                </div>
                            </div>
                            </>;


    var mobilelinks = <><div className="row" style={{padding:'0px 30px',marginTop:'0px',marginBottom:'0px'}}>
                                <img src={logo_image} height="80px" className="cursor-pointer" onClick={()=>goHome()} />
                            </div>
                            <div className="divider"></div>
                            <div className={'row white-neon-link'+homelink} style={{padding:'5px 30px',marginTop:'10px'}}  onClick={()=>logoutUser()} >
                                <div className="top-nav-header-item-text">
                                    REGISTER
                                </div>
                            </div>
                            <div className={'row white-neon-link'+teamslink} style={{padding:'5px 30px'}} onClick={()=>goToDirectory()} >
                                <div className="top-nav-header-item-text">
                                    DIRECTORY
                                </div>
                            </div>
                            <div className="row divider"></div>
                        </>;
    if(eventUserID > 0) {
        mobilelinks = <><div className="row" style={{padding:'0px 30px',marginTop:'0px',marginBottom:'0px'}}>
                                <img src={logo_image} height="80px" className="cursor-pointer" onClick={()=>goHome()} />
                            </div>
                            <div className="divider"></div>
                            <div className={'row white-neon-link'+homelink} style={{padding:'5px 30px',marginTop:'10px'}}  onClick={()=>goHome()} >
                                <div className="top-nav-header-item-text">
                                    HOME
                                </div>
                            </div>
                            <div className={'row white-neon-link'+teamslink} style={{padding:'5px 30px'}} onClick={()=>goToDirectory()} >
                                <div className="top-nav-header-item-text">
                                    DIRECTORY
                                </div>
                            </div>
                            <div className="row white-neon-link" style={{padding:'5px 30px'}} onClick={()=>logoutUser()}>
                                <div className="top-nav-header-item-text">
                                    LOGOUT
                                </div>
                            </div>
                            <div className="row divider"></div>
                        </>;
    }

    if(headerSet == false) {
        setHeader(true);
        setTimeout(function() {
            loadMobileNav();
        },1000);
    }

    return(<div className="row white-text page-width-wide top-nav-header-full" style={{position:'absolute',top:'0px',right:'0px',zIndex:'10001'}}>
                <nav className="transparent z-depth-0">
                    <div className="col s12 m10 offset-m1 transparent nav-wrapper" style={{marginTop:'0px',marginBottom:'0px',position:'relative'}}>
                        <div data-target="mobile-header-nav" className="sidenav-trigger" style={{position:'absolute',right:'10px',top:'7px'}}>
					    	<i className="top-nav-header-left-item material-icons" style={{fontSize:'28px',marginTop:'2px',marginBottom:'0px',cursor:'pointer'}}>menu</i>
					    </div>
                    </div>
                </nav>
                <ul className="sidenav polkadot-purple-background" id="mobile-header-nav" style={{padding:'10px 20px'}}>
                    {mobilelinks}
                </ul>
        </div>);
};

export default HeaderMintItem;