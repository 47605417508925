import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {SplashPage, HomePage, SplashMintPage, TeamDirectoryPage} from "./Sections";

const SplashPolkadotGo = (props:any) => {
    const dispatch = useDispatch();
    const { racerID, accountAddress } = useSelector((state: any) => state.account);

    const [pageSet, setPage] = useState(false);
    const [currentPage, setCurrentPage] = useState(<></>);

    document.addEventListener('DOMContentLoaded', function() {
        
        if(window.location.href.indexOf('register') >= 0) {
            setCurrentPage(<SplashPage wcModal={props.wcModal}  />);
        } else if(window.location.href.indexOf('home') >= 0) {
            setCurrentPage(<HomePage wcModal={props.wcModal}  />);
        } else if(window.location.href.indexOf('mint') >= 0) {
            setCurrentPage(<SplashMintPage wcModal={props.wcModal}  />);
        } else if(window.location.href.indexOf('teams') >= 0) {
            setCurrentPage(<TeamDirectoryPage wcModal={props.wcModal}  />);
        } else {
            setCurrentPage(<SplashPage wcModal={props.wcModal}  />);
        }
    });

    if(pageSet == false) {
        setPage(true);
        if(window.location.href.indexOf('register') >= 0) {
            setCurrentPage(<SplashPage wcModal={props.wcModal}  />);
        } else if(window.location.href.indexOf('home') >= 0) {
            setCurrentPage(<HomePage wcModal={props.wcModal}  />);
        } else if(window.location.href.indexOf('mint') >= 0) {
            setCurrentPage(<SplashMintPage wcModal={props.wcModal}  />);
        } else if(window.location.href.indexOf('teams') >= 0) {
            setCurrentPage(<TeamDirectoryPage wcModal={props.wcModal}  />);
        } else {
            setCurrentPage(<SplashPage wcModal={props.wcModal}  />);
        }
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div className="polkadot-purple-background" style={{position:'relative',width:'auto',height:'auto'}}>
            {currentPage}
            </div>);
};

export default SplashPolkadotGo;