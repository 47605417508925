import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance } from "../../../../../utils";
import {HeaderPolkdotGo} from "../../../../organisms";
import $ from "jquery";

export const TeamDirectoryPage = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { userID, eventUserID, walletID } = useSelector((state:any) => state.account);
    
    /* EXR Event ID */
    const [entrySet, setEntry] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [item_html,setItemHTML] = useState(<></>);

    /* Event ID */
    var partnereventid = 1;
    var partnershortcode = 'dot';
    var apikey = 'h2zcswrivdly7d6v9uo6';
    let signup_user_id = 0;
    let current_wallet_photo = "css/images/nova_icon_color.png";
    let event_category = '';

    function grabEventDirectory() {
        var addData:any = new Object();
        addData.apikey = encodeURIComponent(apikey);
        addData.event_id = encodeURIComponent(partnereventid);
        addData.event_category = encodeURIComponent(event_category);

        axiosInstance.post('event/event_directory/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_item_list = result.data;
                setItemList(new_item_list); 
                showItemsAvailable(new_item_list);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function showItemsAvailable(item_list:any) {
        var itemlist:any = [];
        setItemHTML(itemlist);

        var itemcnt = 0;
        for(var i = 0; i < item_list.length;i++) {
            var company_id = item_list[i]['company_id'];
            var company_name = item_list[i]['company_name'];
            var company_industry = item_list[i]['company_industry'];
            var company_overview = item_list[i]['company_overview'];
            var company_website = item_list[i]['company_website'];
            var company_icon = item_list[i]['company_icon_url'];
            var eventcat = item_list[i]['event_category'];
            var company_links = item_list[i]['company_links'];
            var itemlinks:any = [];
            for(var j = 0; j < company_links.length;j++) {
                var link_network = company_links[j]['network_type'];
                var link_url = company_links[j]['link_url'];


                var linkimage = <></>;
                if(link_network == 'TW') {
                    linkimage = <a href={link_url} target="_blank" className="black-text" title="Twitter X"> 
                                <img src="https://media.polkadot.world/global/twitterx_outlined_icon.png" height="27px" style={{marginTop:'1px'}} />
                                </a>;
                } else if(link_network == 'TG') {
                    linkimage = <a href={link_url} target="_blank" className="black-text" title="Telegram"> 
                                <img src="https://media.polkadot.world/global/telegram_outlined_icon.png" height="28px" />
                                </a>;
                } else if(link_network == 'LI') {
                    linkimage = <a href={link_url} target="_blank" className="black-text" title="LinkedIn"> 
                                <img src="https://media.polkadot.world/global/linkedin_outlined_icon.png" height="30px" />
                                </a>;
                } else if(link_network == 'IG') {
                    linkimage = <a href={link_url} target="_blank" className="black-text" title="Instagram"> 
                                <img src="https://media.polkadot.world/global/instagram_outlined_icon.png" height="30px" />
                                </a>;
                } else if(link_network == 'DD') {
                    linkimage = <a href={link_url} target="_blank" className="black-text" title="Discord"> 
                                <img src="https://media.polkadot.world/global/discord_outlined_icon.png" height="30px" />
                                </a>;
                } else if(link_network == 'GH') {
                    linkimage = <a href={link_url} target="_blank" className="black-text" title="Github"> 
                                <img src="https://media.polkadot.world/global/github_outlined_icon.png" height="27px" />
                                </a>;
                }

                var linkspan = <span className="directory-icon-link">
                                {linkimage}
                               </span>;
                
                itemlinks.push(linkspan);
            }

            var borderTopStyle = '';
            if (i == 0) {
                borderTopStyle = '1px solid black';
            }

            var iconpaddingTop = "3px";
            var iconWidth="70px";
            if(company_name.toUpperCase() == 'CRUST NETWORK') {
                iconWidth="60px";
            } else if(company_name.toUpperCase() == 'SORA CARD') {
                iconWidth="65px";
            } else if(company_name.toUpperCase() == 'EXILED RACERS') {
                iconpaddingTop = "17px";
                iconWidth="80px";
            } else if(company_name.toUpperCase() == 'POLKASWAP') {
                iconWidth="75px";
            } else if(company_name.toUpperCase() == 'ACURAST') {
                iconWidth="75px";
            }

            if(window.innerWidth < 800) {
                if(eventcat == 'Enterprise') {
                    company_industry = company_industry.replace("Decentralized","");
                } else {
                    company_industry = company_industry.replace("Decentralized","Decentral");
                }
            }
            

            var company_website_short = company_website.replace("https:","").replace("http:","").replace("//","").replace("www.","").replace(/\/+$/, '');
            var tmp_html = <div key={event_category+"_"+company_id} className="col s12 l6 black-text" style={{margin:'0px',marginTop:'10px',padding:'0px 5px'}}>
                                <div style={{borderRadius:'10px',padding:'20px 20px',backgroundColor:'rgba(255,255,255,0.86)'}} >
                                    <div className="row" style={{marginBottom:'0px',padding:'0px 0px',display:'flex',flexDirection:'row',cursor:'pointer'}}
                                        id={"item_upper_"+company_id} onClick={(e)=>toggleDirectoryItem(e)} >
                                        <div className="left center-align" style={{minHeight:'50px',width:'80px'}}>
                                            <img key={event_category+"_p"+(company_id)} 
                                                    src={company_icon} 
                                                    width={iconWidth} 
                                                    style={{paddingTop:iconpaddingTop,paddingLeft:'10px',paddingRight:'20px',verticalAlign:'middle'}} />
                                        </div>
                                        <div key={event_category+"_n"+(company_id)} className="left" style={{width:'70%',flex:'1'}}>
                                            <div className="left subheadline-text" style={{width:'100%', fontSize:'18px',fontWeight:'700',letterSpacing:'1.5',marginTop:'-2px'}}>
                                                {company_name.toUpperCase()}
                                            </div>
                                            <div className="left subheadline-text" style={{width:'100%',fontSize:'14px',marginTop:'2px',fontWeight:'400'}}>
                                                <span className="polkadot-pink-text" style={{fontWeight:'600'}}>{eventcat.toUpperCase()}</span> // {company_industry.toUpperCase()}
                                            </div>
                                        </div>
                                        <div className="right right-align">
                                            <span className="material-symbols-outlined right polkadot-purple-text" style={{cursor:'pointer',marginRight:'10px'}}
                                                id={"item_toggle_"+company_id}>add</span>
                                        </div>
                                    </div>
                                    <div id={"item_info_"+company_id}  className="row" style={{display:'none',marginBottom:'0px',padding:'20px 10px 0px 10px'}}>
                                        <div className="left polkadot-purple-background" style={{width:'100%',height:'2px'}}>
                                        </div>
                                        <div className="left subheadline-text" style={{width:'100%',fontWeight:'600',marginTop:'15px',paddingLeft:'10px'}}>
                                            LEARN
                                        </div>
                                        <div className="left" style={{width:'100%',marginTop:'5px',marginBottom:'10px',paddingLeft:'10px',paddingRight:'10px'}}>
                                            {company_overview}
                                        </div>
                                        <div className="left" style={{width:'100%',marginTop:'10px',marginBottom:'20px',paddingLeft:'10px'}}>
                                            <a href={company_website} target="_blank" className="black-text">
                                                <span className="left" style={{paddingRight:'10px'}}>
                                                    <img src='https://media.polkadot.world/global/website_outlined_icon.png' height="22px" />
                                                </span>
                                                <span className="left">
                                                    {company_website_short}
                                                </span>
                                            </a>
                                        </div>
                                        <div className="left polkadot-pink-background" style={{width:'100%',height:'2px'}}>
                                        </div>
                                        <div className="left right-align" style={{width:'100%',marginTop:'15px'}}>
                                            {itemlinks}
                                        </div>
                                    </div>
                                </div>
                            </div>;

            if(itemcnt == 0) {

            }
            itemlist.push(tmp_html);
        }
        setItemHTML(itemlist);
    }

    function switchDirectoryFilter(newfilter:string) {
        if(event_category != newfilter) {
            event_category = newfilter;
            if(newfilter == 'all') {
                event_category = ''
            }
            $(".pink-green-button").each(function(){
                $(this).removeClass('pink-green-button-active');
            });
            $("#directory-filter-"+newfilter).addClass('pink-green-button-active');
            grabEventDirectory();
        }
    }

    function toggleDirectoryItem(e:any) {
        e.preventDefault();
        var itemid = '';
        var currenttarget = e.target;
        while(itemid == '') {
            if(currenttarget.id.indexOf('item_upper_') >= 0) {
                itemid = currenttarget.id.replace("item_upper_","");
            } else {
                currenttarget = currenttarget.parentElement;
            }
            
        }
        
        if($("#item_info_"+itemid).is(":visible")) {
            $("#item_info_"+itemid).slideUp();
            $("#item_toggle_"+itemid).html('add');
        } else {
            $("#item_info_"+itemid).slideDown();
            $("#item_toggle_"+itemid).html('remove');
        }
    }

    if(entrySet == false) {
        setEntry(true);
        grabEventDirectory();
    }
 
    return(<>
            <div style={{position:'relative',width:'auto',height:'auto'}}>
            <div className="row" style={{padding:'0px',margin:'0px',position:'relative',minHeight:'100vh',marginBottom:'0px',overflow:'hidden'}}>
                <div className="show-on-small-only hide-on-med-and-up"
                        style={{position:'fixed',width:'100%',top:'0px',left:'-10vw',opacity:'.25'}}>
                        <img src="https://media.polkadot.world/global/polkadot_main_backdrop.png" style={{width:'120vw'}} />
                    </div>
                <div className="hide-on-small-only show-on-med-and-up center-align" 
                        style={{position:'absolute',width:'100%',top:'-20vh',left:'0px',opacity:'.25'}}>
                            <img src="https://media.polkadot.world/global/polkadot_main_backdrop.png" style={{width:'75vw',minWidth:'800px',maxWidth:'1200px'}} />
                        </div>
                    <HeaderPolkdotGo />
                    <div className="col s12 transparent" style={{padding:'0px',position:'relative',zIndex:'10'}}>
                        
                        <div className="col s12 m10 offset-m1 white-text">
                            <div className="row" style={{margin:'0px',padding:'10px',marginBottom:'10px'}}>
                                <div className="col s12 transparent white-text" style={{margin:'0px',padding:'0px',borderRadius:'10px',paddingBottom:'30px',paddingTop:'30px',minHeight:'80vh'}}>
                                    <div className="row transparent" style={{margin:'0px',padding:'0px',marginBottom:'25px',borderRadius:'10px'}}>
                                        <div className="col s12" style={{fontSize:'16px'}}>
                                            CONSENSUS 2024
                                        </div>
                                        <div className="col s12 headline-text hide-on-small-only show-on-med-and-up" style={{fontSize:'40px',fontWeight:'900'}}>
                                            TEAM DIRECTORY
                                        </div>
                                        <div className="col s12 headline-text show-on-small-only hide-on-med-and-up" style={{fontSize:'26px',fontWeight:'900'}}>
                                            TEAM DIRECTORY
                                        </div>
                                        <div className="col s12" style={{fontSize:'16px',marginTop:'10px'}}>
                                            <div className="left">
                                                <div className="left pink-green-button pink-green-button-active" 
                                                    id="directory-filter-all" onClick={()=>switchDirectoryFilter('all')}>
                                                    ALL
                                                </div>
                                                <div className="left pink-green-button" 
                                                    id="directory-filter-play" onClick={()=>switchDirectoryFilter('play')}>
                                                    PLAY
                                                </div>
                                                <div className="left pink-green-button" 
                                                    id="directory-filter-build" onClick={()=>switchDirectoryFilter('build')}>
                                                    BUILD
                                                </div>
                                                <div className="left show-on-small-only hide-on-med-and-up" style={{width:'100%',fontSize:'10px'}}>
                                                    &nbsp;
                                                </div>
                                                <div className="left pink-green-button" 
                                                    id="directory-filter-interact" onClick={()=>switchDirectoryFilter('interact')}>
                                                    INTERACT
                                                </div>
                                                <div className="left pink-green-button" 
                                                    id="directory-filter-enterprise" onClick={()=>switchDirectoryFilter('enterprise')}>
                                                    ENTERPRISE
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    {item_html}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}