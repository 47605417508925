import axios from 'axios'

const base_CSR_API_URL = 'https://data.exiledracers.com/api/v1/';
const test_CSR_API_URL = 'https://data.spaceracers.club/api/v1/';
const partner_CSR_API_URL = 'https://partner-api.exiledracers.com/api/v1/';

export const current_endpoint = partner_CSR_API_URL;
//export const current_endpoint = test_CSR_API_URL;
//export const current_endpoint = base_CSR_API_URL;

export const axiosInstance = axios.create({
    baseURL: current_endpoint,
    timeout: 120000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});
