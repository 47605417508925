import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {axiosInstance } from "../../../../../utils";
import {HeaderPolkdotGo} from "../../../../organisms";
import {validateEmailText, sendEventEmailCode, checkEventEmailCode, addEventSignupWallet} from "../../../../../utils/signon";
import $ from "jquery";

let current_email = '';

export const HomePage = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { userID, eventUserID, walletID } = useSelector((state:any) => state.account);
    
    /* EXR Event ID */
    const [entrySet, setEntry] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [item_html,setItemHTML] = useState(<></>);
    const [total_collected,setTotalCollected] = useState(0);
    const [total_tickets,setTotalTickets] = useState(0);
    const [total_items,setTotalItems] = useState(0);
    const [userEmail, setUserEmail] = useState('');
    /* Event ID */
    var partnereventid = 1;
    var partnershortcode = 'dot';
    var apikey = 'h2zcswrivdly7d6v9uo6';
    let signup_user_id = 0;
    let current_wallet_photo = "css/images/nova_icon_color.png";

    function grabEventUserInfo() {
        var addData:any = new Object();
        addData.apikey = encodeURIComponent(apikey);
        addData.event_id = encodeURIComponent(partnereventid);
        addData.event_user = encodeURIComponent(eventUserID);

        axiosInstance.post('event/event_user_status/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var user_info = result.data;
                if(user_info.length > 0) {
                    var user_email = user_info[0]['email_address'];
                    current_email = user_email;
                    setUserEmail(user_email);
                    if(user_email.length > 0) {
                        $("#submit_email_success").fadeIn();
                    } else {
                        $("#submit_email_reveal").fadeIn();        
                    }
                }
                grabEventUserItems();       
            }
        }).catch(error => {
            console.log(error);
        });
        
    }
    function grabEventUserItems() {

        var addData:any = new Object();
        addData.apikey = encodeURIComponent(apikey);
        addData.event_id = encodeURIComponent(partnereventid);
        addData.event_user = encodeURIComponent(eventUserID);

        axiosInstance.post('event/event_items/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var new_item_list = result.data;
                setItemList(new_item_list); 
                showItemsAvailable(new_item_list);
                setTotalItems(new_item_list.length);        
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function showItemsAvailable(item_list:any) {
        var itemlist:any = [];
        var collectedcnt = 0;
        for(var i = 0; i < item_list.length;i++) {
            var event_item_id = item_list[i]['event_item_id'];
            var event_item_permalink = item_list[i]['item_permalink'];
            var item_name_split = item_list[i]['item_name'].split(" POAP");
            var item_name = item_name_split[0];
            var items_collected = item_list[i]['items_collected'];

            let item_image = 'https://media.exiledracers.com/dot/polkadot_safe_purple.png';
            if(item_name == 'Play') {
                item_image = 'https://media.exiledracers.com/dot/polkadot_arcade_purple.png';
            } else if(item_name == 'Build') {
                item_image = 'https://media.exiledracers.com/dot/polkadot_keyboard_purple.png';
            } else if(item_name == 'Interact') {
                item_image = 'https://media.exiledracers.com/dot/polkadot_crystal_ball_purple.png';
            } else if(item_name == 'Enterprise') {
                item_image = 'https://media.exiledracers.com/dot/polkadot_coins_purple.png';
            }

            var tmp_html = <div  key={event_item_id+"wrapper_"+items_collected} className="col s6 l3" style={{margin:'0px',padding:'5px'}}>
                                <div key={event_item_id+"_"+items_collected} className="row" style={{
                                                                position:'relative',margin:'0px',padding:'0px',height:'50vh',
                                                                borderRadius:'10px',maxHeight:'60vw',background:'rgba(0,178,254,0.86)',
                                                                overflow:'hidden',fontSize:'18px'}}
                                    id={"itemsquare_"+event_item_permalink}>
                                        
                                        <div style={{position:'absolute',width:'100%',top:'14.5%',left:'0px',height:'70%',
                                            backgroundImage:'url('+item_image+')',backgroundSize:'cover',backgroundPosition:'center',opacity:'.25'}}></div>
                                        <div className="center-align headline-text white-text" style={{position:'absolute',width:'100%',top:'0px',left:'0px',padding:'15px',fontWeight:'700'}}>
                                            {item_name.toUpperCase()}
                                        </div>
                                        <div className={"center-align subheadline-text polkadot-purple-text"} 
                                            style={{position:'absolute',fontSize:'16px',width:'100%',bottom:'0px',left:'0px',padding:'15px',fontWeight:'600',borderTopRightRadius:'0x',borderTopLeftRadius:'0px'}}>
                                                NOT COLLECTED
                                        </div>
                                </div>
                            </div>;

            if ((items_collected >= 1)) {
                collectedcnt = collectedcnt+1;
                item_image = item_list[i]['item_image_url'];

                tmp_html = <div key={event_item_id+"wrapper_"+items_collected} className="col s6 l3" style={{margin:'0px',padding:'5px'}}>
                                <div key={event_item_id+"_"+items_collected} className="row" style={{
                                                                position:'relative',margin:'0px',padding:'0px',height:'50vh',
                                                                borderRadius:'10px',maxHeight:'60vw',background:'rgba(0,178,254,0.86)',
                                                                overflow:'hidden',fontSize:'18px',cursor:'pointer'}}
                                    id={"itemsquare_"+event_item_permalink} onClick={(e)=>goToNFT(e)}>
                                        <div className="center-align headline-text white-text" style={{position:'absolute',width:'100%',top:'0px',left:'0px',padding:'15px',fontWeight:'700'}}>
                                            {item_name.toUpperCase()}
                                        </div>
                                        <div style={{position:'absolute',width:'100%',top:'55px',left:'0px',cursor:'pointer'}}>
                                            <img src={item_image} width="100%" />
                                        </div>
                                        <div className={"center-align subheadline-text polkadot-purple-text polkadot-green-background"} 
                                            style={{position:'absolute',fontSize:'16px',width:'100%',bottom:'0px',left:'0px',padding:'15px',fontWeight:'600',
                                            borderTopRightRadius:'0x',borderTopLeftRadius:'0px'}}>
                                            COLLECTED
                                        </div>
                                </div>
                            </div>;
            }

            itemlist.push(tmp_html);
        }
        var updatedtickets = 25;
        if(current_email.length > 0) {
            updatedtickets = updatedtickets+50;
        }
        updatedtickets = updatedtickets+(collectedcnt*25);
        setTotalTickets(updatedtickets);
        setTotalCollected(collectedcnt);
        setItemHTML(itemlist);
    }

    function showEmailForm() {
        $("#submit_email_reveal").fadeOut();
        $("#submit_email_form").fadeIn();
        $("#submit_email_success").hide();
    }

    async function submitEmailAddress() {
        var useremail = (document.getElementById("userEmailAddress") as HTMLInputElement).value.trim();

        if(useremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(useremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else {
            $("#submit_email_button").hide();
            $("#submit_email_loading").show();

            var addData:any = new Object();
            addData.apikey = encodeURIComponent(apikey);
            addData.event_id = encodeURIComponent(partnereventid);
            addData.event_user = encodeURIComponent(eventUserID);
            addData.email = encodeURIComponent(useremail);

            axiosInstance.post('event/event_user_update/',addData).then(result => {
                if(Object.keys(result.data).length > 0) {
                    setUserEmail(useremail);
                    current_email = useremail;
                    setTimeout(function(){
                        $("#submit_email_reveal").fadeOut();
                        $("#submit_email_form").fadeOut();
                        $("#submit_email_success").fadeIn();
                        var updatedtickets = total_tickets+50;
                        setTotalTickets(updatedtickets);
                    },3000);    
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    function goToNFT(e:any) {
        e.preventDefault();
        var itemid = '';
        var currenttarget = e.target;
        while(itemid == '') {
            if(currenttarget.id.indexOf('itemsquare_') >= 0) {
                itemid = currenttarget.id.replace("itemsquare_","");
            } else {
                currenttarget = currenttarget.parentElement;
            }
            
        }

        var nftid = itemid;
        window.location.href= "#/mint/"+nftid;
    }

    function goToDirectory() {
        window.location.href= "#/teams";
    }

    if(entrySet == false) {
        setEntry(true);
        if(eventUserID > 0) {
            grabEventUserInfo();
        } else {
            window.location.href="/";
        }
    }

    return(<>
            <div style={{position:'relative',width:'auto',height:'auto'}}>
                <div className="row" style={{padding:'0px',margin:'0px',position:'relative',minHeight:'100vh',marginBottom:'0px',overflow:'hidden'}}>
                <div className="show-on-small-only hide-on-med-and-up"
                        style={{position:'absolute',height:'100%',width:'100%',bottom:'0px',left:'0px',opacity:'.25',
                            background:'url(https://media.polkadot.world/global/polkadot_main_backdrop.png)',backgroundSize:'stretch',backgroundPosition:'center'
                        }}></div>
                <div className="hide-on-small-only show-on-med-and-up center-align" 
                        style={{position:'absolute',width:'100%',top:'-20vh',left:'0px',opacity:'.25'}}>
                            <img src="https://media.polkadot.world/global/polkadot_main_backdrop.png" style={{width:'75vw',minWidth:'800px',maxWidth:'1200px'}} />
                        </div>
                <HeaderPolkdotGo />
                <div className="col s12 transparent" style={{padding:'0px',position:'relative',zIndex:'10'}}>
                    
                    <div className="col s12 m10 offset-m1 white-text">
                        <div className="row" style={{margin:'0px',padding:'10px',marginBottom:'10px'}}>
                            <div className="col s12 transparent white-text" style={{margin:'0px',padding:'0px',borderRadius:'10px',paddingBottom:'30px',paddingTop:'30px',minHeight:'80vh'}}>
                                <div className="row transparent" style={{margin:'0px',padding:'0px',borderRadius:'10px'}}>
                                    <div className="col s12 subheadline-text" style={{fontSize:'16px'}}>
                                        CONSENSUS 2024
                                    </div>
                                    <div className="col s12 headline-text hide-on-small-only show-on-med-and-up" style={{fontSize:'40px',fontWeight:'900'}}>
                                        COLLECT TO WIN
                                    </div>
                                    <div className="col s12 headline-text show-on-small-only hide-on-med-and-up" style={{fontSize:'26px',fontWeight:'900'}}>
                                        COLLECT TO WIN
                                    </div>
                                    <div className="col s12 subheadline-text hide-on-small-only show-on-med-and-up" style={{fontSize:'16px',marginTop:'2px'}}>
                                        VISIT EACH STATION. INTERACT WITH TEAMS. COLLECT POAPS. EARN PRIZE LOTTERY TICKETS.
                                    </div>
                                    <div className="col s12 subheadline-text show-on-small-only hide-on-med-and-up" style={{fontSize:'14px',marginTop:'5px',paddingRight:'10px'}}>
                                        VISIT EACH STATION. INTERACT WITH TEAMS. COLLECT POAPS. EARN PRIZE LOTTERY TICKETS.
                                    </div>
                                    <div id="submit_email_reveal" className="col s12" style={{display:'none',fontSize:'16px',marginTop:'30px'}}>
                                        <div className="left pink-green-button" style={{padding:'10px 20px'}} onClick={()=>showEmailForm()}>
                                            REGISTER EMAIL (+50 TICKETS)
                                        </div> 
                                    </div>
                                    <div id="submit_email_form" className="col s12 l6" style={{display:'none',marginTop:'15px'}}>
                                        <div className="row subheadline-text" style={{padding:'0px',margin:'0px',marginBottom:'5px',fontSize:'14px'}}>
                                            ENTER EMAIL (+50 TICKETS):
                                        </div>
                                        <div className="row" style={{padding:'0px',margin:'0px',fontSize:'16px',display:'flex',flexDirection:'row'}}>
                                            <div className="left" style={{flex:'1'}}>
                                                <input id="userEmailAddress" className="squared-bubble-input-round" 
                                                style={{fontSize:'16px',marginLeft:'0px',margin:'0px'}} />
                                            </div> 
                                            <div className="right pink-green-button" id="submit_email_button" 
                                                style={{marginLeft:'10px',padding:'8px 20px',fontSize:'16px',marginRight:'0px'}}
                                                onClick={()=>submitEmailAddress()}>
                                                SUBMIT
                                            </div>
                                            <div id="submit_email_loading" className="preloader-wrapper small active event-register-formbutton right" 
                                                style={{display:'none',height:'30px',width:'30px',marginTop:'7px',marginLeft:'30px',marginRight:'50px'}}>
                                                <div className="spinner-layer spinner-blue-only">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div>
                                                    <div className="gap-patch">
                                                        <div className="circle"></div>
                                                    </div>
                                                    <div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="submit_email_success" className="col s12" style={{display:'none',marginTop:'15px'}}>
                                        <div className="subheadline-text left" style={{fontSize:'16px',color:'white'}}> 
                                            <span className="left material-symbols-outlined white-text">
                                                check_circle
                                            </span>  
                                        </div>
                                        <div className="subheadline-text left" style={{flex:1,fontSize:'16px',color:'white',marginLeft:'5px',marginTop:'-1px'}}> 
                                            <span className="white-text">
                                                EMAIL CONNECTED //&nbsp;
                                            </span>
                                            <span className="polkadot-pink-text" style={{fontWeight:'600'}} >
                                               +50 TICKETS
                                            </span>
                                            
                                        </div>
                                    </div>
                                    <div className="col s12" style={{fontSize:'16px',marginTop:'50px',marginBottom:'10px'}}>
                                        <div className="left subheadline-text" style={{fontWeight:'600'}}>
                                            {total_collected}/{total_items} COLLECTED
                                        </div> 
                                        <div className="right subheadline-text" style={{fontWeight:'600'}}>
                                            {total_tickets} TICKETS EARNED
                                        </div>
                                    </div>
                                </div>
                                {item_html}
                                <div className="row" style={{margin:'0px',padding:'0px'}}>
                                    <div className="col s12 white" style={{height:'2px',marginTop:'80px'}}>
                                    </div>
                                    <div className="col s12 headline-text hide-on-small-only show-on-med-and-up" style={{marginTop:'20px',fontSize:'30px',fontWeight:'900'}}>
                                        TEAM DIRECTORY
                                        <div className="right pink-green-button-active" style={{padding:'10px 20px',fontSize:'14px'}}
                                            onClick={()=>goToDirectory()}>
                                            VIEW DIRECTORY
                                        </div> 
                                    </div>
                                    <div className="col s12 headline-text show-on-small-only hide-on-med-and-up" style={{marginTop:'20px',fontSize:'24px',fontWeight:'900'}}>
                                        TEAM DIRECTORY
                                    </div>
                                    <div className="col s12 subheadline-text" style={{fontSize:'16px',marginTop:'10px',paddingRight:'30px'}}>
                                        LEARN ABOUT THE POLKADOT TEAMS EXHIBITING AT CONSENSUS 2024. FIND TEAM CONTACT INFORMATION TO FOLLOW ALONG AND ENGAGE FURTHER. 
                                    </div>
                                    <div className="col s12" style={{fontSize:'14px',marginTop:'30px',paddingRight:'30px'}}>
                                        <div className="right pink-green-button-active show-on-small-only hide-on-med-and-up" style={{padding:'10px 20px',fontSize:'14px'}}
                                            onClick={()=>goToDirectory()}>
                                            VIEW DIRECTORY
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>);
}