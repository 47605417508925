import $ from "jquery";
import { axiosInstance } from "./axios.helper";

export {LocalStorage} from "./localStorage";
export {axiosInstance} from "./axios.helper"

export const shortenAddress = (address:string) => {
  return `${address.slice(0, 4)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

export function closeAllModals() {
  var modalelems = document.querySelectorAll('.modal');
  for(var i = 0; i < modalelems.length;i++) {
      var modal_instance = window.M.Modal.getInstance(modalelems[i]);
      if(modal_instance) {
          modal_instance.close();
      }
  }
}

export function startPageModals() {
  var model_elems = document.querySelectorAll('.modal');
  var model_instances = window.M.Modal.init(model_elems,{'opacity':0.75,onOpenStart: function(){
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          $(".modal").css({'width':'95%'});
      } else {
          $(".modal").css({'width':'50%'});
      }
  }, onCloseEnd: function(){
      //
  }});
}

export function openPageModal(modalid:string) {
  var modal_elem:any = document.getElementById(modalid);
  var modal_instance:any = window.M.Modal.getInstance(modal_elem);
  modal_instance.open();
}

export function resetCenterHeight() {
  var tmptxtheight:any = $("#splash-main").height();
  var tmpwindowheight:any = $(window).height();
  if(tmpwindowheight > (tmptxtheight+100)) {
      var newtop = ((tmpwindowheight - tmptxtheight - 50) / 2);
      $("#splash-main").css({'top':newtop+'px'});
  } else {
      $("#splash-main").css({'top':'50px'});
  }
}

export function resetBodyWidth() {
  $("body").css({'overflow':'auto','overflow-x':'hidden'});
  resetCenterHeight();
}